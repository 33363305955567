import * as actions from './actions'
import {
  addProduct,
  addProductsToUnifiedCart,
  getCartForDate,
  getUnifiedCartForDate,
  getViewCartForDate,
  removeAllProducts,
  removeProduct,
  removeProductFromUnifiedCart,
  changeBoxPlanForCart
} from 'src/api/sduiService'
import { deserializeUnifiedCart, mapProductProps } from './utils'
import * as orderActions from '../order/actions'
import {
  cartByDateSelector,
  getHomeUnifiedCartByDateSelector,
  getUseUnifiedCart
} from './selectors'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'

export const fetchCartForDate = (date, syncOrderCart = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const useUnifiedCart = getUseUnifiedCart(state)

  if (useUnifiedCart) {
    dispatch(fetchHomeUnifiedCartForDate)
  } else {
    dispatch(fetchOldCartForDate(date, syncOrderCart))
  }
}

export const fetchOldCartForDate = (date, syncOrderCart = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  dispatch(actions.fetchCartForDate({ date }))
  getCartForDate(date)
    .then(res => res.json())
    .then(({ data }) => {
      const cartProducts = data?.products?.map(mapProductProps)
      if (syncOrderCart) {
        const existingCart = cartByDateSelector(state)

        const modifiedProducts = []

        cartProducts.forEach(newProduct => {
          const existingProduct = existingCart.find(
            p => p.inventoryId === newProduct.inventoryId
          )

          if (existingProduct) {
            const quantityDifference =
              newProduct.quantity - existingProduct.quantity
            if (quantityDifference !== 0) {
              modifiedProducts.push({
                product: newProduct,
                qty: quantityDifference
              })
            }
          } else {
            modifiedProducts.push({
              product: newProduct,
              qty: newProduct.quantity
            })
          }
        })

        existingCart.forEach(existingProduct => {
          if (
            !cartProducts.some(
              newProduct =>
                newProduct.inventoryId === existingProduct.inventoryId
            )
          ) {
            modifiedProducts.push({
              product: existingProduct,
              quantityChange: -existingProduct.quantity
            })
          }
        })

        modifiedProducts.forEach(item => {
          if (item.qty > 0) {
            dispatch(
              orderActions.addProduct({
                product: item.product,
                quantity: item.qty
              })
            )
          } else {
            dispatch(
              orderActions.removeProduct({
                product: item.product,
                quantity: item.qty
              })
            )
          }
        })
      }

      dispatch(
        actions.fetchCartForDateSucceed({
          date,
          cart: cartProducts,
          gifts: data?.gifts || []
        })
      )
    })
    .catch(() => {
      dispatch(actions.fetchCartForDateFailed())
    })
}

export const addProductToCart = (date, product, quantity) => (
  dispatch,
  getState
) => {
  const state = getState()
  const useUnifiedCart = getUseUnifiedCart(state)

  if (useUnifiedCart) {
    dispatch(addProductToHomeUnifiedCart(product, quantity))
  } else {
    dispatch(addProductToOldCart(date, product, quantity))
  }
}

export const changeSelectedBox = (date, cartId, boxId) => async dispatch => {
  const unifiedCart = await changeBoxPlanForCart(cartId, boxId)
  const unifiedCartWithBox = {
    ...deserializeUnifiedCart(unifiedCart),
    selectedBoxId: boxId
  }

  dispatch(
    actions.updateBOMSelectedBox({ unifiedCart: unifiedCartWithBox, date })
  )
}

export const addProductToOldCart = (date, product, quantity) => dispatch => {
  dispatch(actions.addProductToCart())
  addProduct(date, product.inventoryId, quantity, product.id)
    .then(res => res.json())
    .then(res => {
      const productInCart = res?.items?.find(
        p => p.inventoryId === product.inventoryId
      )
      dispatch(
        actions.addProductToCartSucceed({
          date,
          product: { ...product, ...productInCart }
        })
      )
      // to sync menu cart
      dispatch(orderActions.addProduct({ product, quantity }))
    })
    .catch(() => {
      dispatch(actions.addProductToCartFailed())
    })
}

export const removeProductFromCart = (date, product) => (
  dispatch,
  getState
) => {
  const state = getState()
  const useUnifiedCart = getUseUnifiedCart(state)

  if (useUnifiedCart) {
    dispatch(
      removeProductFromHomeUnifiedCart([
        {
          inventory_id: product.inventoryId,
          quantity: 1
        }
      ])
    )
    dispatch(
      orderActions.removeProduct({
        product: product,
        quantity: 1
      })
    )
  } else {
    dispatch(removeProductFromOldCart(date, product))
  }
}

export const removeProductFromOldCart = (date, product) => dispatch => {
  dispatch(actions.removeProductFromCart())
  removeProduct(date, product.inventoryId, product.id)
    .then(res => res.json())
    .then(res => {
      const productInCart = res?.items?.find(
        p => p.inventoryId === product.inventoryId
      )
      dispatch(
        actions.removeProductFromCartSucceed({
          date,
          product: productInCart,
          inventoryId: product.inventoryId
        })
      )
      // to sync menu cart
      dispatch(orderActions.removeProduct({ product, quantity: 1 }))
    })
    .catch(() => {
      dispatch(actions.removeProductFromCartFailed())
    })
}

export const clearCart = (dispatch, getState) => {
  const state = getState()
  const useUnifiedCart = getUseUnifiedCart(state)

  if (useUnifiedCart) {
    dispatch(clearHomeUnifiedCart)
  } else {
    dispatch(clearOldCart)
  }
}

export const clearOldCart = (dispatch, getState) => {
  const state = getState()
  const selectedDelivery = selectedDeliverySelector(state)

  dispatch(actions.clearCart())
  removeAllProducts(selectedDelivery?.date)
    .then(() => {
      dispatch(
        actions.clearCartSucceed({
          date: selectedDelivery?.date
        })
      )
      // to sync menu cart
      dispatch(orderActions.clearOrder())
    })
    .catch(() => {
      dispatch(actions.clearCartFailed())
    })
}

export const fetchUnifiedCartForDate = date => dispatch => {
  dispatch(actions.fetchUnifiedCartForDate({ date }))
  getUnifiedCartForDate(date)
    .then(res => res.json())
    .then(data => {
      const unifiedCart = deserializeUnifiedCart(data)
      dispatch(
        actions.fetchUnifiedCartForDateSucceed({
          date,
          unifiedCart: unifiedCart
        })
      )
    })
    .catch(error => {
      console.log('error', error)
      dispatch(actions.fetchCartForDateFailed())
    })
}

export const addProductsToUnifiedCartForCartId = async (
  cartId,
  products,
  dispatch
) => {
  dispatch(actions.addProductToUnifiedCart())
  return addProductsToUnifiedCart(
    cartId,
    products.map(product => ({
      inventory_id: product.inventoryId || product.inventory_id,
      quantity: product.quantity
    }))
  )
    .then(res => res.json())
    .then(cart => {
      const mappedCart = deserializeUnifiedCart(cart)
      dispatch(
        actions.addProductToUnifiedCartSucceed({
          date: cart.shipping_date,
          unifiedCart: mappedCart
        })
      )
      return mappedCart
    })
    .catch(() => {
      dispatch(actions.addProductToUnifiedCartFailed())
    })
}

export const removeProductsFromUnifiedCartForCartId = (
  cartId,
  products,
  dispatch
) => {
  dispatch(actions.removeProductFromUnifiedCart())
  return removeProductFromUnifiedCart(
    cartId,
    products.map(product => ({
      inventory_id: product.inventoryId || product.inventory_id,
      quantity: product.quantity
    }))
  )
    .then(res => {
      if (res.ok) return res
      throw new Error('something went wrong trying to delete product.')
    })
    .then(res => res.json())
    .then(cart => {
      const mappedCart = deserializeUnifiedCart(cart)
      dispatch(
        actions.removeProductFromUnifiedCartSucceed({
          date: cart.shipping_date,
          unifiedCart: mappedCart
        })
      )
      return mappedCart
    })
    .catch(() => {
      dispatch(actions.removeProductFromUnifiedCartFailed())
      throw new Error('something went wrong trying to delete product.')
    })
}

/*
___________________________________________________
|                                                 |
|                HOME UNIFIED CART                |
|_________________________________________________|
*/

export const fetchHomeUnifiedCartForDate = (dispatch, getState) => {
  const state = getState()
  const selectedDelivery = selectedDeliverySelector(state)

  dispatch(
    actions.fetchHomeUnifiedCartForDate({ date: selectedDelivery?.date })
  )
  getViewCartForDate(selectedDelivery?.date)
    .then(res => res.json())
    .then(data => {
      const products = data.products?.map(mapProductProps) || []
      const recommendations = data.recommendations?.map(mapProductProps) || []
      const addOns = data.addOns?.map(mapProductProps) || []
      dispatch(
        actions.fetchHomeUnifiedCartForDateSucceed({
          date: selectedDelivery?.date,
          cartId: data.cart?.cart_id,
          products,
          recommendations,
          addOns,
          gifts: data.gifts || []
        })
      )
      dispatch(
        orderActions.syncProducts({
          menuMeals: products.concat(recommendations).concat(addOns),
          cartProducts: data.cart?.products?.map(product => ({
            inventoryId: product.inventory_id,
            qty: product.quantity
          }))
        })
      )
    })
    .catch(error => {
      console.log('Error fetching cart: ', error)
      dispatch(
        actions.fetchHomeUnifiedCartForDateFailed({
          date: selectedDelivery?.date
        })
      )
    })
}

export const addProductToHomeUnifiedCart = (product, quantity) => (
  dispatch,
  getState
) => {
  dispatch(actions.addProductToHomeUnifiedCart())

  const state = getState()
  const selectedDelivery = selectedDeliverySelector(state)
  const homeUnifiedCart = getHomeUnifiedCartByDateSelector(state)

  addProductsToUnifiedCart(homeUnifiedCart.cartId, [
    {
      inventory_id: product.inventoryId,
      quantity
    }
  ])
    .then(res => res.json())
    .then(unifiedCart => {
      dispatch(
        actions.addProductToHomeUnifiedCartSucceed({
          date: selectedDelivery?.date,
          unifiedCart,
          addedProducts: [product]
        })
      )
      dispatch(
        orderActions.addProduct({
          product: {
            ...product,
            quantity,
            qty: quantity,
            cart: {
              quantity
            }
          },
          quantity
        })
      )
    })
    .catch(error => {
      console.log('Error adding product:', error)
      dispatch(actions.addProductToHomeUnifiedCartFailed())
    })
}

export const removeProductFromHomeUnifiedCart = products => (
  dispatch,
  getState
) => {
  dispatch(actions.removeProductFromHomeUnifiedCart())

  const state = getState()
  const selectedDelivery = selectedDeliverySelector(state)
  const homeUnifiedCart = getHomeUnifiedCartByDateSelector(state)

  removeProductFromUnifiedCart(homeUnifiedCart.cartId, products)
    .then(res => res.json())
    .then(unifiedCart => {
      dispatch(
        actions.removeProductFromHomeUnifiedCartSucceed({
          date: selectedDelivery?.date,
          unifiedCart
        })
      )
    })
    .catch(error => {
      console.log('Error removing product: ', error)
      dispatch(actions.removeProductFromHomeUnifiedCartFailed())
    })
}

export const clearHomeUnifiedCart = (dispatch, getState) => {
  const state = getState()
  const homeUnifiedCart = cartByDateSelector(state)

  const products = homeUnifiedCart.map(product => ({
    inventory_id: product.inventoryId,
    quantity: product.quantity
  }))

  dispatch(removeProductFromHomeUnifiedCart(products))
  dispatch(orderActions.clearOrder())
}

export const clearRecommendations = (dispatch, getState) => {
  const state = getState()
  const homeUnifiedCart = getHomeUnifiedCartByDateSelector(state)

  const products = homeUnifiedCart.recommendations.map(product => ({
    inventory_id: product.inventoryId,
    quantity: product.quantity
  }))

  dispatch(removeProductFromHomeUnifiedCart(products))
  products.forEach(product => {
    dispatch(
      orderActions.removeProduct({ product, quantity: product.quantity })
    )
  })
}
