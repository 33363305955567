import * as R from 'ramda'

import {
  checkIfMealExistIntoWishListProfiles,
  mealsAreEquals
} from '../../../../utils/utils'

import { FILTER_IDS } from '../MenuBy/Filters/constants'
import store from 'src/store'
import { MENU_CATEGORIES, SORTING_MEAL_PROPERTIES } from 'src/constants/menu'
import {
  CUISINES_ICONS_MAPPING,
  DIETS_ICONS_MAPPING,
  HIGHLIGHTS_ICONS_MAPPING,
  MACRONUTRIENTS_BY_PREFERENCE_ID,
  PREFERENCES_ICONS_MAPPING,
  PROTEINS_ICONS_MAPPING
} from 'src/componentsV2/Filters/CuiFiltersPanel/constants'
import { isBundle } from 'src/common/MealMenu/utils'
import {
  FULL_MENU_ALL_CATEGORIES,
  FULL_MENU_PRODUCT_TYPES,
  OTHER_SUBTYPE
} from '../MenuBy/FiltersExperimentV3/constantsFullMenu'
import { uniqBy } from 'ramda'
import Fuse from 'fuse.js'

const compareNumbers = (a, b) => {
  return parseInt(a, 16) - parseInt(b, 16)
}

const trimAndLower = str => (str || '').trim().toLowerCase()

const sortStars = meals => {
  return (
    meals &&
    meals.sort((a, b) => {
      const aStars = a.stars || Number.MIN_SAFE_INTEGER
      const bStars = b.stars || Number.MIN_SAFE_INTEGER

      if (aStars > bStars) return -1
      if (aStars < bStars) return 1
      return 0
    })
  )
}

const sortMeals = (meals, filterBy) => {
  return (
    meals &&
    meals.sort((a, b) => {
      const aNutritionalFact =
        a.nutritionalFacts?.[filterBy] || Number.MAX_SAFE_INTEGER
      const bNutritionalFact =
        b.nutritionalFacts?.[filterBy] || Number.MAX_SAFE_INTEGER

      return compareNumbers(aNutritionalFact, bNutritionalFact)
    })
  )
}

const sortMealsByMenuSorting = meals => {
  meals.sort(
    (a, b) =>
      a.sorting?.[SORTING_MEAL_PROPERTIES.ALL] -
      b.sorting?.[SORTING_MEAL_PROPERTIES.ALL]
  )
  return 'all'
}

export const sortBy = (meals, currentSortFilter) => {
  const _meals_ = [...meals]

  sortMealsByMenuSorting(_meals_)

  if (!currentSortFilter) {
    return _meals_
  }

  const filterBy =
    currentSortFilter[0] && currentSortFilter[0].filterBy.toLowerCase()
  const order = currentSortFilter[0].order

  // Order Low to High
  if (
    order &&
    order.sort[0].order === 'ASC' &&
    order.sort[1].order === 'DESC'
  ) {
    return sortMeals(_meals_, filterBy)
  } else if (filterBy.includes('review')) {
    return sortStars(_meals_)
  } else {
    return _meals_
  }
}

const filterByChef = (meals, chefs) => {
  return chefs.length
    ? meals.filter(meal =>
        meal.meals // Case for bundles
          ? filterBundleByChef(meal, chefs)
          : filterMealByChef(meal, chefs)
      )
    : meals
}

const filterMealByChef = (meal, chefs) => {
  return chefs.find(chef => chef.value === meal.chef_id)
}

const filterBundleByChef = (bundle, chefs) => {
  return bundle.meals.some(meal =>
    chefs.find(chef => chef.value === meal.chef_id)
  )
}

const filterMealsByMealName = (meals, searchInputText) => {
  return searchInputText.length
    ? meals.filter(meal =>
        meal.name.toLocaleLowerCase().includes(searchInputText)
      )
    : meals
}

const filterMealsByShortDescription = (meals, searchInputText) => {
  return searchInputText.length
    ? meals.filter(meal => {
        if (meal.short_description) {
          return meal.short_description
            .toLocaleLowerCase()
            .includes(searchInputText)
        }
        return false
      })
    : meals
}

const filterMealsByChefName = (meals, searchInputText) => {
  return searchInputText.length
    ? meals.filter(meal => {
        const chefName = `${meal.chef_firstname} ${meal.chef_lastname}`
        return chefName.toLocaleLowerCase().includes(searchInputText)
      })
    : meals
}

const filterMealsBySearchInputText = (filteredMeals, searchInput) => {
  const mealsByMealName = filterMealsByMealName(filteredMeals, searchInput)
  const mealsByShortDescription = filterMealsByShortDescription(
    filteredMeals,
    searchInput
  )
  const mealsByChefName = filterMealsByChefName(filteredMeals, searchInput)

  const joinFilteredMeals = mealsByMealName.concat(
    mealsByShortDescription,
    mealsByChefName
  )

  return joinFilteredMeals.filter((item, index) => {
    return joinFilteredMeals.indexOf(item) === index
  })
}

const filterMealsByFuzzysearch = (
  searchByValues,
  searchInput,
  filteredMeals
) => {
  const fuse = new Fuse(searchByValues || [], {
    includeScore: true,
    minMatchCharLength: 3,
    keys: [
      { name: 'mealName', weight: 100 },
      { name: 'chefName', weight: 1 },
      { name: 'mealDescription', weight: 1 }
    ],
    ignoreLocation: true,
    threshold: 0.3,
    includeMatches: true
  })
  const result = fuse.search(searchInput)
  const mealsFound = result.map(result =>
    filteredMeals.find(m => mealsAreEquals(result.item, m))
  )
  return mealsFound.filter(mf => !!mf)
}

const getQuickFiltersMeals = (
  quickFilterId,
  quickFilterPattern,
  profiles,
  filteredMeals
) => {
  switch (true) {
    case quickFilterId === FILTER_IDS.FULL_MENU_FOR_YOU:
    case quickFilterId === FILTER_IDS.FULL_MENU_YOUVE_TRIED:
      return filteredMeals
    default:
      return (
        filteredMeals &&
        filteredMeals.filter(meal => {
          let matches = true
          const pattern = quickFilterPattern
          if (pattern) {
            matches = meal.filter_by.includes(pattern)
          }
          if (quickFilterId === FILTER_IDS.FULL_MENU_FAVORITES && profiles) {
            matches = checkIfMealExistIntoWishListProfiles(
              meal.entity_id,
              profiles
            )
          }

          return matches
        })
      )
  }
}

export const buildFullMenuCategory = (meals, nextFilters = []) => {
  const state = store.getState()

  const currentFullMenuCategory = state.filters?.menuSelected && {
    ...state.filters?.menuSelected
  }

  if (!currentFullMenuCategory) {
    return null
  }

  const selectedFilters =
    nextFilters.length > 0
      ? nextFilters
      : state.filters?.fullMenuProductTypesSelected

  // The following method builds only the categories to be displayed,
  // depending on the current quickfilter and the filters applied.
  const newCategories =
    buildProductTypesSubcategories(selectedFilters, currentFullMenuCategory) ||
    []

  if (selectedFilters?.length > 0) {
    if (!!currentFullMenuCategory.categories) {
      currentFullMenuCategory.categories = newCategories
    } else {
      currentFullMenuCategory.meals = applyNewSubcategoriesToNonFullMenuCategories(
        newCategories,
        meals
      )
      return currentFullMenuCategory
    }
  }

  if (currentFullMenuCategory.categories) {
    currentFullMenuCategory.categories = currentFullMenuCategory.categories.map(
      category =>
        applyCategoryFiltersToMeals(
          category,
          meals,
          selectedFilters?.length === 0
        )
    )
  } else {
    currentFullMenuCategory.meals = meals
  }

  return currentFullMenuCategory
}

const buildProductTypesSubcategories = (
  selectedFilters,
  currentFullMenuCategory
) => {
  const newCategories = []

  selectedFilters?.forEach(categoryToShow => {
    const hasSubtypes = categoryToShow.filterTypes != null
    const hasToShowAllSubtypes =
      (hasSubtypes && categoryToShow.filterTypes.length === 0) || !hasSubtypes

    if (currentFullMenuCategory?.categories) {
      currentFullMenuCategory?.categories?.forEach(category => {
        if (category.categoryId === categoryToShow.id) {
          if (hasToShowAllSubtypes) {
            newCategories.push(category)
            return
          }
          if (hasSubtypes) {
            newCategories.push({
              ...category,
              filterTypes: category.filterTypes?.filter(subtype =>
                categoryToShow.filterTypes
                  .map(f => f.pattern)
                  .includes(subtype.pattern)
              )
            })
          }
        }
      })
    } else {
      newCategories.push(categoryToShow)
    }
  })
  return newCategories
}

const applyCategoryFiltersToMeals = (category, meals, includeOtherCategory) => {
  // Some subcateogries, as bundles, drinks and treats doesn't have subtypes, therefore
  // We should include meals/bundles at subcategory level
  if (!category.filterTypes) {
    const productsToShow = meals.filter(meal =>
      meal.filter_by.find(fb => fb === category.filterByPattern)
    )
    return {
      ...category,
      meals: productsToShow
    }
  }

  const mealsFilteredByCategory = meals.filter(meal =>
    meal.filter_by.find(fb => fb === category.filterByPattern)
  )

  const filterTypes = category.filterTypes?.map(subtype => {
    const mealsFilteredByType = mealsFilteredByCategory.filter(meal =>
      meal.filter_by.find(fb => fb === subtype.filterByPattern)
    )
    return {
      ...subtype,
      meals: mealsFilteredByType
    }
  })

  if (includeOtherCategory) {
    const plainMeals = filterTypes?.flatMap(ft => ft.meals)

    const otherMeals = []

    mealsFilteredByCategory.forEach(meal => {
      if (!plainMeals?.find(m => mealsAreEquals(m, meal))) {
        otherMeals.push(meal)
      }
    })

    if (otherMeals.length > 0) {
      filterTypes.push({ ...OTHER_SUBTYPE, meals: otherMeals })
    }
  }

  return {
    ...category,
    filterTypes
  }
}

export const filterRepeatedMeals = meals => {
  const seenIds = new Set()
  const filteredArray = []
  for (const meal of meals) {
    if (!seenIds.has(meal.inventoryId)) {
      seenIds.add(meal.inventoryId)
      filteredArray.push(meal)
    }
  }
  return filteredArray
}

export const getOtherMeals = (allMeals, fullMenuQuickfilter) => {
  if (fullMenuQuickfilter?.id === FILTER_IDS.FULL_MENU_ALL) {
    const other = []
    const mealsInFME = getFullMenuCurrentViewMeals(fullMenuQuickfilter)

    allMeals.forEach(allMeal => {
      if (!mealsInFME.find(m => mealsAreEquals(m, allMeal))) {
        other.push(allMeal)
      }
    })

    return other
  }

  return []
}

export const addOtherCategory = (
  currentQuickFilter,
  otherMeals,
  filtersApplied
) => {
  if (
    !filtersApplied &&
    currentQuickFilter?.id === FILTER_IDS.FULL_MENU_ALL &&
    otherMeals?.length > 0
  ) {
    const otherCategory = {
      ...FULL_MENU_ALL_CATEGORIES.OTHER,
      meals: otherMeals
    }
    currentQuickFilter.categories.push(otherCategory)
  }

  return currentQuickFilter
}

const applyNewSubcategoriesToNonFullMenuCategories = (newCategories, meals) => {
  let filteredMeals = []
  newCategories.forEach(category => {
    const mealsFilteredByCategory = meals.filter(meal =>
      meal.filter_by.find(fb => fb === category.filterByPattern)
    )
    if (!category.filterTypes) {
      const bundleCategory =
        category.productType === FULL_MENU_PRODUCT_TYPES.BUNDLES

      const productsToShow = bundleCategory
        ? meals.filter(meal => isBundle(meal))
        : mealsFilteredByCategory
      filteredMeals.push(...productsToShow)
    }

    if (category.filterTypes?.length > 0) {
      category.filterTypes.forEach(subtype => {
        const mealsFilteredByType = mealsFilteredByCategory.filter(meal =>
          meal.filter_by.find(fb => fb === subtype.filterByPattern)
        )

        filteredMeals.push(...mealsFilteredByType)
      })
    }
  })

  return filteredMeals
}

export const filterMeals = (
  filters,
  meals,
  currentChefFilter,
  currentQuickFilter,
  searchInput,
  profiles,
  macronutrientFilters,
  searchByValues,
  useFuzzySearch
) => {
  let filteredMeals = meals || []
  const state = store.getState()
  const menuSelected = state.filters?.menuSelected?.id
  if (
    menuSelected === FILTER_IDS.FULL_MENU_FOR_YOU &&
    state.filters?.selectedCategoryId === MENU_CATEGORIES.MEALS
  ) {
    filteredMeals =
      state.menu?.menu?.meals?.filter(
        meal => !!meal.sorting?.[SORTING_MEAL_PROPERTIES.FOR_YOU]
      ) ?? []
    filteredMeals = uniqBy(meal => meal.inventoryId, filteredMeals)
  }
  const filtersByType = []

  if (!filters || R.isEmpty(filters)) return meals

  if (currentQuickFilter && !R.isEmpty(currentQuickFilter.pattern)) {
    filteredMeals = getQuickFiltersMeals(
      currentQuickFilter.id,
      currentQuickFilter.pattern,
      profiles,
      filteredMeals
    )
  }

  // filter type needed for Bundle filter logic.
  if (filters.proteins && filters.proteins.length) {
    filtersByType.push({
      type: 'proteins',
      value: filters.proteins.map(elem => elem.types || trimAndLower(elem.name))
    })
  }

  if (filters.diets && filters.diets.length) {
    filtersByType.push({
      type: 'diets',
      value: filters.diets.map(diet => trimAndLower(diet.name))
    })
  }

  if (filters.cuisines && filters.cuisines.length) {
    filtersByType.push({
      type: 'cuisines',
      value: filters.cuisines.map(cuisine => trimAndLower(cuisine.label))
    })
  }

  filteredMeals = !filtersByType.length
    ? filteredMeals
    : filteredMeals.filter(meal =>
        meal.meals // If has meals, is a bundle
          ? filterBundleByTypes(meal, filtersByType)
          : filterMealByTypes(meal, filtersByType)
      )

  if (filters.preferences && filters.preferences.length) {
    filteredMeals = filteredMeals.filter(meal =>
      meal.meals
        ? filterBundleByPreferences(meal, filters.preferences)
        : filterMealByPreferences(meal, filters.preferences)
    )
  }

  if (searchInput && searchInput.length > 0) {
    if (useFuzzySearch) {
      filteredMeals = filterMealsByFuzzysearch(
        searchByValues,
        searchInput,
        filteredMeals
      )
    } else {
      filteredMeals = filterMealsBySearchInputText(filteredMeals, searchInput)
    }
  }
  if (
    macronutrientFilters &&
    Object.values(macronutrientFilters).some(mf => mf.applied)
  ) {
    filteredMeals = filterMealsByMacronutrients(
      filteredMeals,
      macronutrientFilters
    )
  }

  if (currentChefFilter && !R.isEmpty(currentChefFilter)) {
    return filterByChef(filteredMeals, currentChefFilter)
  }

  return filteredMeals
}

const filterBundleByTypes = (bundle, filtersByType) => {
  return filtersByType.every(filter => {
    if (filter.type === 'proteins') {
      return filterBundleByProteins(bundle, filter.value)
    }
    if (filter.type === 'diets') {
      return filterBundleByDiets(bundle, filter.value)
    }
    if (filter.type === 'cuisines') {
      return filterBundleByCuisines(bundle, filter.value)
    }
    return false
  })
}
const filterMealByPreferences = (meal, preferences) => {
  return preferences.every(preference =>
    meal.filter_by.includes(trimAndLower(preference.name))
  )
}

const filterBundleByPreferences = (bundle, preferences) => {
  return preferences.every(preference =>
    bundle.meals.every(meal =>
      meal.filter_by.includes(trimAndLower(preference.name))
    )
  )
}

const filterBundleByProteins = (bundle, proteinFilterValue) => {
  return proteinFilterValue.some(value =>
    Array.isArray(value)
      ? value
          .map(v => v.toLowerCase())
          .includes(bundle.meals.every(meal => meal.meatType?.toLowerCase())) ||
        value.some(v =>
          bundle.meals.every(meal => meal.filter_by.includes(v.toLowerCase()))
        )
      : bundle.meals.every(meal => meal.filter_by.includes(value.toLowerCase()))
  )
}

const filterBundleByDiets = (bundle, dietFilterValue) => {
  return dietFilterValue.some(value =>
    bundle.meals.every(meal => meal.filter_by.includes(value.toLowerCase()))
  )
}

const filterBundleByCuisines = (bundle, cuisineFilterValue) => {
  return cuisineFilterValue.some(value =>
    bundle.meals.some(meal => meal.filter_by.includes(value.toLowerCase()))
  )
}

const filterMealByTypes = (meal, filtersByType) => {
  return filtersByType.every(filter =>
    filter.value.some(value =>
      Array.isArray(value)
        ? value
            .map(v => v.toLowerCase())
            .includes(meal.meatType?.toLowerCase()) ||
          value.some(v => meal.filter_by.includes(v.toLowerCase()))
        : meal.filter_by.includes(value.toLowerCase())
    )
  )
}

export const filterCategory = (meals, currentQuickFilter) => {
  let filteredMeals = [...meals]
  if (currentQuickFilter && currentQuickFilter.pattern) {
    filteredMeals =
      filteredMeals &&
      filteredMeals.filter(
        meal =>
          meal.sidesSubCategoryNames &&
          meal.sidesSubCategoryNames.includes(currentQuickFilter.pattern)
      )
  }
  return filteredMeals
}

export const shouldUpdateFilters = (
  currentFilters,
  prevCurrentFilters,
  currentChefFilter,
  prevCurrentChefFilter,
  selectedCategoryId,
  prevSelectedCategoryId,
  currentQuickFilter,
  prevCurrentQuickFilter,
  searchInput,
  prevSearchInput,
  menuByFilter,
  prevMenuByFilter,
  currentMenu,
  prevCurrentMenu,
  fullMenuHidePremium,
  prevFullMenuHidePremium,
  fullMenuProductTypeSelectedFilters,
  prevFullMenuProductTypeSelectedFilters,
  currentMacronutrientFilters,
  prevMacronutrientFilters
) => {
  if (
    !R.equals(currentFilters, prevCurrentFilters) ||
    !R.equals(currentChefFilter, prevCurrentChefFilter) ||
    !R.equals(selectedCategoryId, prevSelectedCategoryId) ||
    !R.equals(currentQuickFilter, prevCurrentQuickFilter) ||
    !R.equals(searchInput, prevSearchInput) ||
    !R.equals(menuByFilter, prevMenuByFilter) ||
    !R.equals(currentMenu, prevCurrentMenu) ||
    !R.equals(fullMenuHidePremium, prevFullMenuHidePremium) ||
    !R.equals(
      fullMenuProductTypeSelectedFilters,
      prevFullMenuProductTypeSelectedFilters
    ) ||
    !R.equals(currentMacronutrientFilters, prevMacronutrientFilters)
  ) {
    return true
  }
  return false
}

export const isAppliedFilters = (currentFilters, currentChefFilter) => {
  if (
    currentFilters &&
    (currentFilters.diets.length ||
      currentFilters.preferences.length ||
      currentFilters.proteins.length) &&
    currentChefFilter.length
  ) {
    return true
  }
  return false
}

const isDifferentSortCriteria = (prevCurrentSortBy, currentSortBy) => {
  return (
    currentSortBy.length !== prevCurrentSortBy.length || // different length
    currentSortBy.some(
      currentElem =>
        prevCurrentSortBy.findIndex(
          prevElem =>
            currentElem.name.toLowerCase() !== prevElem.name.toLowerCase()
        ) > -1
    )
  )
}

export const isApliedSortBy = (prevCurrentSortBy, currentSortBy) => {
  return (
    prevCurrentSortBy &&
    currentSortBy &&
    isDifferentSortCriteria(prevCurrentSortBy, currentSortBy)
  )
}

export const isApliedSearch = (searchInput, prevSearchInput) =>
  searchInput && !R.equals(searchInput, prevSearchInput)

export const mapDietFilterDataV2 = filter => ({
  id: filter.id,
  filterName: filter.name,
  filterLabel: filter.label,
  filterTypes: [],
  filterSvg: DIETS_ICONS_MAPPING[filter.label.toLowerCase()],
  filterImage: ''
})

export const mapProteinFilterDataV2 = filter => ({
  id: filter.id,
  filterName: filter.name,
  filterLabel: filter.name,
  filterTypes: filter.types,
  filterSvg:
    PROTEINS_ICONS_MAPPING[filter.name.replace(/ /g, '_').toLowerCase()],
  filterImage: ''
})

export const mapPreferencesFilterDataV2 = filter => ({
  id: filter.id,
  filterName: filter.name,
  filterTypes: [],
  filterLabel: filter.name,
  filterSvg:
    PREFERENCES_ICONS_MAPPING[filter.name.replace(/ /g, '_').toLowerCase()],
  filterImage: ''
})

export const mapChefsFilterDataV2 = filter => ({
  id: filter.id,
  filterName: filter.name,
  filterTypes: [],
  filterLabel: filter.name,
  filterSvg: null,
  filterImage: filter.image,
  fallbackImage: filter.fallbackImage
})

export const mapCuisinesFilterDataV2 = filter => ({
  id: filter.id,
  filterName: filter.name,
  filterTypes: [],
  filterLabel: filter.label,
  filterSvg: null,
  filterImage:
    CUISINES_ICONS_MAPPING[filter.label.replace(/-/g, '_').toLowerCase()]
})

export const mapFullMenuProductTypesFilterData = filter => ({
  id: filter.categoryId,
  filterName: filter.label,
  filterTypes: filter.filterTypes,
  filterLabel: filter.label,
  filterByPattern: filter.filterByPattern
})

export const HIGHLIGHT_FILTERS = [
  {
    id: FILTER_IDS.FULL_MENU_HIDE_PREMIUM,
    filterName: 'hidePremium',
    pattern: 'favorite',
    filterLabel: 'Hide Premium',
    filterSvg: HIGHLIGHTS_ICONS_MAPPING.hidePremium
  }
]

export const getFullMenuCurrentViewMeals = currentFullMenu => {
  if (!currentFullMenu) return []
  return (
    currentFullMenu.meals ||
    currentFullMenu.categories
      ?.map(category => {
        if (category.filterTypes) {
          return category.filterTypes
            .map(filterType => filterType.meals)
            .flatMap(m => m)
        }
        return category.meals
      })
      .flatMap(m => m)
  )
}

export const getFullMenuQuickfilterCategories = quickfilter => {
  if (!quickfilter?.categories) return null
  return quickfilter.categories
    .filter(
      category =>
        category.meals?.length > 0 ||
        category.filterTypes?.some(filterType => filterType.meals.length > 0)
    )
    .map(category => {
      return {
        label: category.label,
        icon: category.image,
        filterTypes: category.filterTypes
          ? category.filterTypes
              .filter(filterType => filterType.meals.length > 0)
              .map(filterType => filterType.name)
          : null
      }
    })
}

const filterMealsByMacronutrients = (meals, macronutrients) => {
  const appliedFilters = Object.values(macronutrients).filter(mf => mf.applied)
  let filteredMeals = meals
  appliedFilters.forEach(mf => {
    const { currentMinRange, currentMaxRange, name } = mf
    filteredMeals = filteredMeals.filter(meal => {
      const nutritionalFacts = meal.nutritionalFacts
      const nutritionFact = nutritionalFacts?.[name]
      if (!nutritionFact) return false

      return (
        parseInt(nutritionFact) <= currentMaxRange &&
        parseInt(nutritionFact) >= currentMinRange
      )
    })
  })

  return filteredMeals
}

export const buildMacronutrientFilterById = (id, _min, max) => {
  const macronutrientValues = MACRONUTRIENTS_BY_PREFERENCE_ID[id]

  if (!macronutrientValues) return null

  return {
    macronutrientName: macronutrientValues.name,
    min: macronutrientValues.min,
    max: macronutrientValues.max ?? max
  }
}
